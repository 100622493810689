import React, {useCallback, useEffect, useRef, useState} from "react";
import {Card, Col, Collapse, List, notification, Row, Select, Statistic, Typography} from "antd";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import SockJS from "sockjs-client";
import * as Stomp from "@stomp/stompjs";
import L from "leaflet";
import "leaflet-rotatedmarker";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

// Fix default icon issue
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const { Option } = Select;

const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const MainPage = () => {
    const [messages, setMessages] = useState([]);
    const [positions, setPositions] = useState({});
    const [focusedDeviceId, setFocusedDeviceId] = useState(null);
    const [focusedDeviceData, setFocusedDeviceData] = useState(null);
    const [wsStatus, setWsStatus] = useState("disconnected"); // 웹소켓 상태 추가
    const stompClientRef = useRef(null);
    const userClickedMapRef = useRef(false);
    const latestFocusedDeviceIdRef = useRef(focusedDeviceId);

    const connectWebSocket = useCallback(() => {
        if (stompClientRef.current && stompClientRef.current.connected) {
            console.log("Already connected");
            return;
        }

        setWsStatus("connecting");
        const socket = new SockJS("https://bis.secretcode.kr/app/ws-js");
        const stompClient = Stomp.over(socket);

        stompClient.connect(
            {},
            (frame) => {
                console.log("Connected: " + frame);
                setWsStatus("connected"); // 웹소켓 상태 설정

                stompClient.subscribe("/broadcast/location", (message) => {
                    console.log("Received message: " + message.body);
                    const location = JSON.parse(message.body);
                    const { deviceId, latitude, longitude, bearing, speed } = location;

                    setMessages((prevMessages) => {
                        const newMessages = [
                            {
                                id: prevMessages.length > 0 ? prevMessages[0].id + 1 : 1,
                                text: message.body,
                                deviceId,
                            },
                            ...prevMessages,
                        ];

                        if (newMessages.length > 5) {
                            newMessages.splice(5); // Keep only the latest 5 messages
                        }

                        setPositions((prevPositions) => {
                            const color = prevPositions[deviceId]?.color || getRandomColor();
                            let newBearing = bearing;
                            if (speed < 1) {
                                // Adjust this threshold value as needed
                                newBearing = prevPositions[deviceId]?.bearing || 0;
                            }
                            const updatedPosition = { position: [latitude, longitude], color, bearing: newBearing, speed };

                            if (deviceId === latestFocusedDeviceIdRef.current) {
                                setFocusedDeviceData(updatedPosition);
                            }

                            return {
                                ...prevPositions,
                                [deviceId]: updatedPosition,
                            };
                        });

                        return newMessages;
                    });
                });

                stompClientRef.current = stompClient;
            },
            (error) => {
                console.log("Connection error: " + error);
                setWsStatus("disconnected"); // 웹소켓 상태 설정
                setTimeout(() => {
                    if (stompClientRef.current && !stompClientRef.current.connected) {
                        console.log("Reconnecting...");
                        connectWebSocket();
                    }
                }, 5000); // 재접속 시도
            }
        );

        return () => {
            if (stompClientRef.current && stompClientRef.current.connected) {
                stompClientRef.current.disconnect();
                setWsStatus("disconnected"); // 웹소켓 상태 설정
            }
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                if (stompClientRef.current && stompClientRef.current.connected) {
                    stompClientRef.current.disconnect();
                    setWsStatus("disconnected");
                }
            } else {
                connectWebSocket();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        connectWebSocket();

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            if (stompClientRef.current && stompClientRef.current.connected) {
                stompClientRef.current.disconnect();
                setWsStatus("disconnected");
            }
        };
    }, [connectWebSocket]);

    useEffect(() => {
        latestFocusedDeviceIdRef.current = focusedDeviceId;
        if (focusedDeviceId !== null && positions[focusedDeviceId]) {
            setFocusedDeviceData(positions[focusedDeviceId]);
        }
    }, [focusedDeviceId, positions]);

    const previousPositionsRef = useRef({});

    useEffect(() => {
        const previousPositions = previousPositionsRef.current;
        Object.keys(positions).forEach((deviceId) => {
            if (!previousPositions[deviceId]) {
                notification.success({
                    message: "New Device Added",
                    description: `Device ID: ${deviceId} has been added.`,
                    type: "info",
                });
            }
        });
        previousPositionsRef.current = positions;
    }, [positions]);

    const MapWithFocus = ({ positions, focusedDeviceId }) => {
        const map = useMap();

        useEffect(() => {
            if (focusedDeviceId && positions[focusedDeviceId]) {
                map.setView(positions[focusedDeviceId].position, map.getZoom());
            }
        }, [focusedDeviceId, positions, map]);

        useMapEvents({
            click: () => {
                userClickedMapRef.current = true;
                setFocusedDeviceId(null);
                setFocusedDeviceData(null);
            },
        });

        return null;
    };

    const createCustomIcon = (deviceId, color, bearing) => {
        const div = document.createElement("div");
        div.innerHTML = `
            <div style="display: flex; flex-direction: column; align-items: center;">
                <i class="fas fa-arrow-circle-up" style="color: ${color}; font-size: 24px;"></i>
                <span style="color: ${color}; font-size: 12px; margin-top: 4px;">${deviceId}</span>
            </div>
        `;
        return div;
    };

    const getIndicatorColor = (status) => {
        switch (status) {
            case "connected":
                return "lime";
            case "connecting":
                return "orange";
            default:
                return "red";
        }
    };

    return (
        <Card>
            <h1 className="p-0 m-0 font-bold">
                <span>SecretCode GpsTracker</span>
                <span className={"text-xs text-yellow-400"}> Demo</span>
            </h1>
            <Row className={"m-0.5"} style={{ position: "relative" }}>
                <Col span={24}>
                    <Card>
                        <MapContainer center={[35.5777967, 129.3794733]} zoom={16} style={{ height: "40vh" }}>
                            <Card size={"small"} className={"absolute top-1 right-1 p-0 m-0 opacity-60"} style={{ zIndex: 1000 }}>
                                <Row>
                                    <Typography.Text>연결상태</Typography.Text>
                                </Row>
                                <Row className={"justify-center"}>
                  <span>
                    {" "}
                      <i className={"fas fa-circle"} style={{ color: getIndicatorColor(wsStatus) }} />
                  </span>
                                </Row>
                            </Card>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {Object.keys(positions).map((deviceId) => (
                                <Marker
                                    key={`${deviceId}-${positions[deviceId].bearing}`} // 이 부분을 추가
                                    position={positions[deviceId].position}
                                    icon={L.divIcon({
                                        html: createCustomIcon(deviceId, positions[deviceId].color, positions[deviceId].bearing).innerHTML,
                                        className: "",
                                    })}
                                    rotationAngle={positions[deviceId].bearing}
                                    rotationOrigin="center"
                                    eventHandlers={{
                                        click: () => {
                                            setFocusedDeviceId(deviceId);
                                            setFocusedDeviceData(positions[deviceId]);
                                        },
                                    }}
                                >
                                    <Popup>{`Device ID: ${deviceId}`}</Popup>
                                </Marker>
                            ))}
                            <MapWithFocus positions={positions} focusedDeviceId={focusedDeviceId} />
                        </MapContainer>
                    </Card>
                </Col>
            </Row>
            <Row className={"m-0.5"}>
                <Col span={24}>
                    <Card>
                        <h3>Online Device</h3>
                        <Select
                            className={"mt-0.5"}
                            style={{ width: "100%" }}
                            placeholder="Device를 선택하세요"
                            value={focusedDeviceId} // 자동 선택된 deviceId 적용
                            onChange={(deviceId) => {
                                setFocusedDeviceId(deviceId);
                                setFocusedDeviceData(positions[deviceId]);
                                userClickedMapRef.current = true; // 맵 클릭으로 인한 포커스 방지
                            }}
                        >
                            {Object.keys(positions).map((deviceId) => (
                                <Option key={deviceId} value={deviceId}>
                                    {deviceId}
                                </Option>
                            ))}
                        </Select>
                    </Card>
                </Col>
            </Row>
            <Row className={"m-0.5"}>
                <Col span={12}>
                    <Card>
                        <Statistic title={"속도(Km/h)"} value={focusedDeviceData ? focusedDeviceData.speed.toFixed(2) : "-"} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic title={"방향"} value={focusedDeviceData ? focusedDeviceData.bearing.toFixed(2) + " °" : "-"} />
                    </Card>
                </Col>
            </Row>
            <Row className={"m-0.5"}>
                <Col span={24}>
                    <Collapse>
                        <CollapsePanel header={"Logs"}>
                            <List
                                itemLayout="horizontal"
                                dataSource={messages}
                                renderItem={(message) => (
                                    <List.Item style={{ padding: 0 }}>
                                        <List.Item.Meta
                                            title={`Message ID: ${message.id}`}
                                            description={<pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{message.text}</pre>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </CollapsePanel>
                    </Collapse>
                </Col>
            </Row>
        </Card>
    );
};

export default MainPage;
