import React from 'react';
import 'leaflet/dist/leaflet.css';
import './App.css';
import Mainpage from "./pages/mainpage";

const App = () => {
    return (
        <div className="app">
            <Mainpage/>
        </div>
    );
};

export default App;
